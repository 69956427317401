<!--
  * @desc:
  * @author: 肖
  * @create_date: 2021/6/5
  * @BSD
-->
<template>
  <div class="page-wrap pt188">
    <!-- 顶部 -->
    <top-bar-arrow>
      <div slot="title" class="title-image">
        历史订单
      </div>
    </top-bar-arrow>
    <div class="main">
      <div class="order-wrap">
        <template v-if="list && list.length > 0">
          <van-list
              v-model="loading"
              :finished="isFinsh"
              finished-text="没有更多了"
              @load="init"
              class="list-scroll-div"
          >
            <template v-for="(item, index) in list">
              <div class="product-order-item-wrap" :key="index">
                <div class="product-order-item box-shadow" :key="index" @click="gotoDetail(item)">
                  <div class="left">
                    <img :src="item.small_image_url" mode="" />
                  </div>
                  <div class="center">
                    <div class="line1">{{ item.product_name }} </div>
                  </div>
                  <div class="right">
                    <div class="text">{{ getStatus(item.box_status) }}</div>
                    <van-icon name="arrow" />
                  </div>
                </div>
              </div>
            </template>
          </van-list>
        </template>
        <template v-else>
          <my-empty class="empty-wrap">
            <div class="message-wrap">
              <div class="line1">暂无历史订单，选购盲盒加入抽盒潮流吧</div>
              <div class="line2">
                <div class="btn">去选盒</div>
              </div>
            </div>
          </my-empty>
        </template>
      </div>
    </div>

  </div>
</template>

<script>
  import { getHistory } from '@/common/api/shop'
  import topBarArrow from '../../components/top-bar-arrow'
  import myEmpty from '../../components/my-empty'
  export default {
    components: {
      topBarArrow,
      myEmpty
    },
    data() {
      return {
        list: [],
        page: 1,
        isFinsh: false,
        loading: false
      };
    },
    mounted () {
      this.init()
    },
    methods: {
      getStatus (status) {
        let str = ''
        switch (status){
          case 1:
            str = '待签发'
            break;
          case 2:
            str = '已签发'
            break;
          case 3:
            str = '换货中'
            break;
        }
        return str
      },
      init () {
        getHistory({
          data: {
            page: this.page
          },
          success: res => {
            if (res.code == 200) {
              this.list = this.list.concat(res.data.list)
              this.loading = false;
              this.page = this.page + 1
              if (this.list.length == res.data.total) {
                this.isFinsh = true
              }
            }
          }
        })
      },
      gotoDetail (item) {
        // if (item.logistics_order_status == 0) {
        //   uni.navigateTo({
        //     url: '/pagesSign/expressDetailWaitting/expressDetailWaitting?logistics_order_no=' + item.logistics_order_no
        //   })
        // }
        // if (item.logistics_order_status == 1) {
        //   uni.navigateTo({
        //     url: '/pagesSign/expressDetailDelivered/expressDetailDelivered?logistics_order_no=' + item.logistics_order_no
        //   })
        // }
        // if (item.logistics_order_status == 2) {
        //   uni.navigateTo({
        //     url:'/pagesSign/expressDetailExchange/expressDetailExchange?logistics_order_no=' + item.logistics_order_no
        //   })
        // }
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import "./historyOrder.scss";
</style>

